import React, {useState} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {useGetMeQuery} from '@graphql/generated/graphql';
import {useLoader} from 'context/loaderContext';

export enum Selections {
  Home,
  Login,
  Orders,
  Account,
}

type SelectionType = {
  selection: Selections;
  text: string;
  url: string;
  svg: JSX.Element;
  svgSelected: JSX.Element;
};

const selectionsData: SelectionType[] = [
  {
    selection: Selections.Home,
    text: 'Home',
    url: '/',
    svg: <Image src="/icons/navbar/home-inactive.svg" alt="Home" width={40} height={25} />,
    svgSelected: <Image src="/icons/navbar/home-active.svg" alt="Home" width={40} height={25} />,
  },
  {
    selection: Selections.Login,
    text: 'Login',
    url: '/login',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
        />
      </svg>
    ),
    svgSelected: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];

const selectionsWithMeData: SelectionType[] = [
  {
    selection: Selections.Home,
    text: 'Home',
    url: '/',
    svg: <Image src="/icons/navbar/home-inactive.svg" alt="Home" width={40} height={25} />,
    svgSelected: <Image src="/icons/navbar/home-active.svg" alt="Home" width={40} height={25} />,
  },
  {
    selection: Selections.Orders,
    text: 'Orders',
    url: '/mix-orders',
    svg: <Image src="/icons/navbar/orders-inactive.svg" alt="Orders" width={20} height={25} />,
    svgSelected: <Image src="/icons/navbar/orders-active.svg" alt="Orders" width={20} height={25} />,
  },
  {
    selection: Selections.Account,
    text: 'Profile',
    url: '/account',
    svg: <Image src="/icons/navbar/account-inactive.svg" alt="Profile" width={18} height={25} />,
    svgSelected: <Image src="/icons/navbar/account-active.svg" alt="Profile" width={18} height={25} />,
  },
];

interface IBottomNavbarProps {
  defaultSelection?: Selections;
}

const BottomNavbar: React.FC<IBottomNavbarProps> = ({defaultSelection}) => {
  const {setShowLoader} = useLoader();
  const [currentSelection, setCurrentSelection] = useState<Selections>(defaultSelection ?? selectionsData[0].selection);

  const {data} = useGetMeQuery();
  if (!data) return null;

  const {me} = data;
  const selections = me ? selectionsWithMeData : selectionsData;

  return (
    <section>
      <div className="navbarShadow fixed bottom-0 left-0 right-0 bg-white max-w-md mx-auto py-2 px-6 flex justify-around items-center">
        {selections.map(({selection, url, svg, svgSelected, text}) => {
          const selected = currentSelection === selection;
          return (
            <Link key={`${selection}-${url}-${text}`} href={url} passHref>
              <a
                className={`relative flex flex-col justify-between items-center ${selected ? 'text-active' : 'text-inactive'}`}
                onClick={() => {
                  setCurrentSelection(selection);
                  setShowLoader(true);
                }}>
                {selected ? svgSelected : svg}
                <p className="uppercase lexend-regular">{text}</p>
              </a>
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default BottomNavbar;
